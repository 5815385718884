import { reactive } from "vue";
import { api } from "@/api-client";

class UserInfo {
    private _isAuthenticated = false;
    private _id: number | null = null;
    private _userName = "";
    private _fullName = "";
    private _sessionExpirationTime = null as Date | null;
    private _canViewPreparation = false;
    private _canViewVehiclePage = false;
    private _canViewVehicleRoutePage = false;
    private _canUpdateVehicleRoutes = false;
    private _canViewReserves = false;
    private _canRedirectReserves = false;
    private _canViewIncidents = false;
    private _canModifyIncidents = false;
    private _canViewAdmin = false;
    private _canViewMetrics = false;
    private _canViewDeparturesTableView = false;
    private _canViewJobsMonitoring = false;
    private _canViewJobOrders = false;
    private _canViewExternalServicesMonitoring = false;
    private _canViewJobDelays = false;
    private _canProcessJobDelays = false;
    private _canInitiateExport = false;
    private _defaultView = "/";
    private _canEditServiceAccountDefaultView = false;
    private _canSetVehiclePubliclyAccessible = false;
    private _canConnectToExternalServicesHub = false;
    private _canConnectToPubliclyUnavailableVehiclesHub = false;
    private _canConnectToExpiredUpdatesHub = false;

    private _canViewTerminalManagement = false;
    private _canViewTerminalDepartures = false;
    private _canViewTerminalArrivals = false;

    private _roleType: api.RoleType | null = null;

    public get isAuthenticated() {
        return this._isAuthenticated;
    }

    public get id() {
        return this._id;
    }

    public get fullName() {
        return this._fullName;
    }

    public get username() {
        return this._userName;
    }

    public get sessionExpirationTime() {
        return this._sessionExpirationTime;
    }

    public get canViewPreparation() {
        return this._canViewPreparation;
    }

    public get canViewVehiclePage() {
        return this._canViewVehiclePage;
    }

    public get canViewVehicleRoutePage() {
        return this._canViewVehicleRoutePage;
    }

    public get canUpdateVehicleRoutes() {
        return this._canUpdateVehicleRoutes;
    }

    public get canViewIncidents() {
        return this._canViewIncidents;
    }

    public get canModifyIncidents() {
        return this._canModifyIncidents;
    }

    public get CanViewAdmin() {
        return this._canViewAdmin;
    }

    public get canViewMetrics() {
        return this._canViewMetrics;
    }

    public get canViewReserves() {
        return this._canViewReserves;
    }

    public get canRedirectReserves() {
        return this._canRedirectReserves;
    }

    public get canViewJobsMonitoring() {
        return this._canViewJobsMonitoring;
    }

    public get canViewDeparturesTableView() {
        return this._canViewDeparturesTableView;
    }

    public get canViewJobOrders() {
        return this._canViewJobOrders;
    }

    public get canViewJobDelays() {
        return this._canViewJobDelays;
    }

    public get canProcessJobDelays() {
        return this._canProcessJobDelays;
    }

    public get canViewExternalServicesMonitoring() {
        return this._canViewExternalServicesMonitoring;
    }

    public get canInitiateExport() {
        return this._canInitiateExport;
    }

    public get defaultView() {
        return this._defaultView;
    }

    public get canEditServiceAccountDefaultView() {
        return this._canEditServiceAccountDefaultView;
    }

    public get roleType() {
        return this._roleType;
    }

    public setSessionExpirationTime(time: Date) {
        return (this._sessionExpirationTime = time);
    }

    public get canSetVehiclePubliclyAccessible() {
        return this._canSetVehiclePubliclyAccessible;
    }

    public get canConnectToExternalServicesHub() {
        return this._canConnectToExternalServicesHub;
    }

    public get canConnectToPubliclyUnavailableVehiclesHub() {
        return this._canConnectToPubliclyUnavailableVehiclesHub;
    }

    public get canConnectToExpiredUpdatesHub() {
        return this._canConnectToExpiredUpdatesHub;
    }

    public get canViewTerminalManagement() {
        return this._canViewTerminalManagement;
    }
    public get canViewTerminalDepartures() {
        return this._canViewTerminalDepartures;
    }
    public get canViewTerminalArrivals() {
        return this._canViewTerminalArrivals;
    }

    public async refreshUserInfo() {
        await new api.AuthenticationClient()
            .me()
            .then((user) => {
                this._id = user.id || null;
                this._userName = user.userName || "";
                this._fullName = user.fullName || "";
                this._isAuthenticated = this._id != null;
                this._canViewPreparation = user.canViewPreparations;
                this._canViewVehiclePage = user.canViewVehiclePage;
                this._canViewVehicleRoutePage = user.canViewVehicleRoutePage;
                this._canUpdateVehicleRoutes = user.canUpdateVehicleRoutes;
                this._canViewReserves = user.canViewReserves;
                this._canRedirectReserves = user.canRedirectReserves;
                this._canViewIncidents = user.canViewIncidents;
                this._canModifyIncidents = user.canModifyIncidents;
                this._canViewAdmin = user.canViewAdmin;
                this._canViewMetrics = user.canViewMetrics;
                this._canViewDeparturesTableView = user.canViewDeparturesTableView;
                this._canViewJobsMonitoring = user.canViewJobsMonitoring;
                this._canViewJobOrders = user.canViewJobOrders;
                this._canViewExternalServicesMonitoring = user.canViewExternalServicesMonitoring;
                this._canViewJobDelays = user.canViewJobDelays;
                this._canProcessJobDelays = user.canProcessJobDelays;
                this._canInitiateExport = user.canInitiateExport;
                this._defaultView = user.defaultView ?? "/";
                this._canEditServiceAccountDefaultView = user.canEditServiceAccountDefaultView;
                this._canSetVehiclePubliclyAccessible = user.canSetVehiclePubliclyAccessible;
                this._roleType = user.roleType ?? null;
                this._canConnectToExternalServicesHub = user.canConnectToExternalServicesHub;
                this._canConnectToPubliclyUnavailableVehiclesHub = user.canConnectToPubliclyUnavailableVehiclesHub;
                this._canConnectToExpiredUpdatesHub = user.canConnectToExpiredUpdatesHub;
                this._canViewTerminalManagement = user.canViewTerminalManagement;
                this._canViewTerminalDepartures = user.canViewTerminalDepartures;
                this._canViewTerminalArrivals = user.canViewTerminalArrivals;
            })
            .catch((error: api.ApiException) => {
                this.reset();
                return error;
            });
    }

    public reset() {
        this._id = null;
        this._fullName = "";
        this._userName = "";
        this._isAuthenticated = false;
        this._sessionExpirationTime = null;
        this._roleType = null;
    }
}

const userInfo = reactive(new UserInfo());
export default userInfo;
