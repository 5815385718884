import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { api } from "@/api-client";
import ReserveVehicleViewModel = api.ReserveVehicleViewModel;
import { ReserveWorkTypeFilter } from "@/features/reserves/ReserveWorkTypeFilter";
import { hasValue } from "@/helpers/Utils";
import { formatTime } from "@/helpers/DateFormatter";
import moment from "moment-timezone";

export class ReserveVehiclesListFilters extends ReserveWorkTypeFilter {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    marks: string[] = [];
    models: string[] = [];
    divisions: string[] = [];
    technicalConditions: string[] = [];
    vehicleFreeTimeFrom: string | undefined;
    vehicleFreeTimeTo: string | undefined;

    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly NO_DIVISION = "Osakond puudub";
    static readonly NO_MARK = "Mark puudub";
    static readonly NO_MODEL = "Mudel puudub";

    readonly STORAGE_KEY = "ReserveVehiclesListFilters";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const storage = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.searchString = route.query.search ? String(route.query.search) : undefined;
            this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
            this.marks = route.query.marks ? String(route.query.marks).split(",") : [];
            this.models = route.query.models ? String(route.query.models).split(",") : [];
            this.divisions = route.query.divisions ? String(route.query.divisions).split(",") : [];
            this.technicalConditions = route.query.technicalConditions ? String(route.query.technicalConditions).split(",") : [];
            this.vehicleFreeTimeFrom = route.query.freeTimeFrom ? (route.query.freeTimeFrom as string) : undefined;
            this.vehicleFreeTimeTo = route.query.freeTimeTo ? (route.query.freeTimeTo as string) : undefined;
        } else if (storage) {
            const data = JSON.parse(storage);
            this.searchString = data.searchString ?? undefined;
            this.vehicleTypes = data.vehicleTypes ?? [];
            this.marks = data.marks ?? [];
            this.models = data.models ?? [];
            this.divisions = data.divisions ?? [];
            this.technicalConditions = data.technicalConditions ?? [];
            this.vehicleFreeTimeFrom = data.freeTimeFrom ?? undefined;
            this.vehicleFreeTimeTo = data.freeTimeTo ?? undefined;
        }

        super.map(route);
    }

    async persist(router: typeof Router) {
        const query = {
            ...super.createQuery(),
            search: this.searchString?.length ? this.searchString : undefined,
            vehicleTypes: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            marks: this.marks.length ? this.marks : undefined,
            models: this.models.length ? this.models : undefined,
            divisions: this.divisions.length ? this.divisions : undefined,
            technicalConditions: this.technicalConditions.length ? this.technicalConditions : undefined,
            vehicleFreeTimeFrom: this.vehicleFreeTimeFrom?.length ? this.vehicleFreeTimeFrom : undefined,
            vehicleFreeTimeTo: this.vehicleFreeTimeTo?.length ? this.vehicleFreeTimeTo : undefined,
        };

        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(query));

        await router.replace({
            query: query,
        });
    }

    clearAllFilters() {
        this.searchString = undefined;
        this.vehicleTypes = [];
        this.marks = [];
        this.models = [];
        this.divisions = [];
        this.technicalConditions = [];
        this.vehicleFreeTimeFrom = undefined;
        this.vehicleFreeTimeTo = undefined;
        super.clearAllFilters();
    }

    applyOn(vehicles: Array<ReserveVehicleViewModel>): Array<ReserveVehicleViewModel> {
        return (super.applyOn(vehicles) as Array<ReserveVehicleViewModel>)
            .filter((vehicle) => filterVehicleType(this.vehicleTypes, vehicle))
            .filter((vehicle) => filterByMark(this.marks, vehicle))
            .filter((vehicle) => filterByModel(this.models, vehicle))
            .filter((vehicle) => filterByDivision(this.divisions, vehicle))
            .filter((vehicle) => filterByTechnicalCondition(this.technicalConditions, vehicle))
            .filter((vehicle) => filterBySearchString(this.searchString, vehicle))
            .filter((vehicle) => filterByVehicleFreeTime(this.vehicleFreeTimeFrom, vehicle))
            .filter((vehicle) => filterByVehicleFreeTime(this.vehicleFreeTimeTo, vehicle));

        function filterVehicleType(vehicleTypes: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!vehicleTypes.length) {
                return true;
            }
            return vehicleTypes.includes(vehicle?.type == null ? ReserveVehiclesListFilters.NO_VEHICLE : vehicle.type.label());
        }

        function filterByMark(marks: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!marks.length) {
                return true;
            }

            return hasValue(vehicle.mark) ? marks.includes(vehicle.mark) : marks.includes(ReserveVehiclesListFilters.NO_MARK);
        }

        function filterByModel(models: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!models.length) {
                return true;
            }

            return hasValue(vehicle.model) ? models.includes(vehicle.model) : models.includes(ReserveVehiclesListFilters.NO_MODEL);
        }

        function filterByDivision(divisions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!divisions.length) {
                return true;
            }
            return vehicle.division == null ? divisions.includes(ReserveVehiclesListFilters.NO_DIVISION) : divisions.includes(vehicle.division);
        }

        function filterByTechnicalCondition(technicalConditions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!technicalConditions.length) {
                return true;
            }
            return technicalConditions.includes(vehicle.repairStatus.technicalConditionLabel());
        }

        function filterBySearchString(searchString: string | undefined, vehicle: ReserveVehicleViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            return (vehicle?.garageNumber?.toLowerCase().includes(searchString.toLowerCase()) || vehicle?.registrationNumber?.toLowerCase().includes(searchString.toLowerCase())) ?? false;
        }

        function filterByVehicleFreeTime(freeTime: string | undefined, vehicle: ReserveVehicleViewModel): boolean {
            if (!freeTime?.length || !vehicle.jobs.length) {
                return true;
            }

            for (let i = 0; i < vehicle.jobs.length; i++) {
                const jobStart = formatTime(vehicle.jobs[i].runStart);
                const jobEnd = formatTime(vehicle.jobs[i].runEnd);

                const isJobStartSameDay = moment(vehicle.jobs[i].runStart).isSame(moment(), "day");
                const isJobEndSameDay = moment(vehicle.jobs[i].runEnd).isSame(moment(), "day");

                const hasNextJob = i != vehicle.jobs.length - 1;
                const hasPreviousJob = i != 0;

                const nextJobStart = hasNextJob ? formatTime(vehicle.jobs[i + 1].runStart) : undefined;
                const previousJobEnd = hasPreviousJob ? formatTime(vehicle.jobs[i - 1].runEnd) : undefined;

                const isNextJobStartNextDay = hasNextJob && moment(vehicle.jobs[i + 1].runStart).isSame(moment().add(1, "day"), "day");
                const isPreviousJobEndPreviousDay = hasPreviousJob && moment(vehicle.jobs[i - 1].runEnd).isSame(moment().subtract(1, "day"), "day");

                const hasFreeTimeBeforeJob = isJobStartSameDay && jobStart >= freeTime;
                const hasFreeTimeAfterJob = isJobEndSameDay && jobEnd <= freeTime;

                const hasFreeTimeBetweenCurrentAndLastJob = hasFreeTimeBeforeJob && ((hasPreviousJob && previousJobEnd! <= freeTime) || isPreviousJobEndPreviousDay);
                const hasFreeTimeBetweenCurrentAndNextJob = hasFreeTimeAfterJob && ((hasNextJob && nextJobStart! >= freeTime) || isNextJobStartNextDay);

                if (!hasPreviousJob && !hasNextJob && (hasFreeTimeAfterJob || hasFreeTimeBeforeJob)) {
                    return true;
                }
                if (!hasPreviousJob && hasNextJob && (hasFreeTimeBeforeJob || hasFreeTimeBetweenCurrentAndNextJob)) {
                    return true;
                }
                if (hasPreviousJob && !hasNextJob && (hasFreeTimeAfterJob || hasFreeTimeBetweenCurrentAndLastJob)) {
                    return true;
                }
                if (hasPreviousJob && hasNextJob && (hasFreeTimeBetweenCurrentAndLastJob || hasFreeTimeBetweenCurrentAndNextJob)) {
                    return true;
                }
            }

            return false;
        }
    }
}
